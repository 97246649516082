import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import CorrectIcon from '../../assets/img/svg/correct.svg';

import './style.scss';
import {RecallProps} from "./RecallProps";
import ym from 'react-yandex-metrika';

function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

const Recall = ({refProp, brand}: RecallProps) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm();
    const [isFormSend, setIsFormSend] = useState<boolean>(false);

    const onSubmit = (data: any) => {
        setIsFormSend((prev) => !prev);

        let d:Date = new Date();
        var date:Date = new Date(d.getTime() + (1000 * 60 * 60 * 24));

        var request = {
            fields: {
                TITLE: `${brand == "BB" ? "ББ" : "СВ"}: ${data.city} - ${data.name}`,
                RESPONSIBLE_ID: 3465,
                CREATED_BY: 737,
                DESCRIPTION: `Новая заявка с ${brand == "BB" ? "ББ" : "СВ"}. \n\n` +
                 `<b>Дата</b>: ${new Date().toLocaleString()}\n` +
                    `<b>Бренд</b>: ${brand == "BB" ? "ББ" : "СВ"}\n` +
                    `<b>Город</b>: ${data.city}\n` +
                    `<b>Имя</b>: ${data.name}\n` +
                    `<b>Телефон</b>: ${data.phone}\n` +
                    `<b>Емейл</b>: ${data.email}\n` +
                    `<b>Комментарий</b>: ${data.comment}\n\n\n\n` +
                    `Требуется связаться в течении 4х часов.`
                 ,
                GROUP_ID: 499,
                AUDITORS: [brand == "BB" ? 817 : 4897,825],
                TASK_CONTROL: "Y",
                DEADLINE: `${date.getFullYear()}-${padTo2Digits(date.getMonth() + 1)}-${date.getDate()}T10:00:00+03:00`
            }
        }
        fetch('https://sushivesla.bitrix24.ru/rest/825/5mxljlcocens9dk0/tasks.task.add', {
            method: 'POST',
            headers: {
                "Accept": '*/*',
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request)
        });
        ym('reachGoal', 'formSubmit', {city: data.city});
        console.log(data);
    };


    const onResetForm = () => {
        setIsFormSend(false);
        reset();
    };

    return (
        <div className="recall" ref={refProp}>
            <div className="container">
                <div className="recall__inner">
                    <div className="recall__left">
                        <h2 className="recall__title">Мы перезвоним</h2>
                        <div className="recall__subtitle">
                            Заполни форму заявки и мы свяжемся с тобой
                            в ближайшее время, подберём для тебя самые выгодные условия
                            сотрудничества
                        </div>
                    </div>
                    <div className="recall__right">
                        {!isFormSend ? (
                            <form onSubmit={handleSubmit(onSubmit)} className="recall__form">
                                <input
                                    type="text"
                                    placeholder="Город"
                                    className="recall__input"
                                    {...register('city', {required: true, maxLength: 80})}
                                    style={errors.city && {border: '1px solid #ff0056'}}
                                />
                                <input
                                    type="text"
                                    placeholder="Имя"
                                    className="recall__input"
                                    {...register('name', {required: true, maxLength: 100})}
                                    style={errors.name && {border: '1px solid #ff0056'}}
                                />
                                <input
                                    type="tel"
                                    placeholder="Телефон"
                                    className="recall__input"
                                    {...register('phone', {
                                        required: true,
                                        minLength: 6
                                    })}
                                    style={errors.phone && {border: '1px solid #ff0056'}}
                                />
                                <input
                                    type="text"
                                    placeholder="E-mail"
                                    className="recall__input"
                                    {...register('email', {
                                        required: false,
                                    })}
                                    style={errors.email && {border: '1px solid #ff0056'}}
                                />
                                <textarea
                                    placeholder="Комментарий (не обязательно)"
                                    className="recall__textarea"
                                    {...register('comment', {required: false, maxLength: 1000})}
                                ></textarea>
                                <button type="submit" className="recall__button">
                                    <span>отправить заявку</span>
                                </button>
                            </form>
                        ) : (
                            <div className="recall__complete">
                                <img src={CorrectIcon} className="recall__icon"/>
                                <div className="recall__complete-title">
                                    Спасибо!<br/> Мы скоро с вами свяжемся
                                </div>
                                <button onClick={onResetForm} className="recall__button">
                                    <span>Вернуться в начало</span>
                                </button>
                            </div>
                        )}
                        <div className="recall__terms">
                            Заполняя форму, я принимаю условия&nbsp;
                            <a href="" className="recall__link">
                                политики обработки персональных данных
                            </a>
                            &nbsp;и даю согласие на обработку личных данных.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Recall;
