import React from 'react';
import './style.scss';
import {BannerProps} from "./bannerProps";

const Banner = ({ scrollToRecall, content }: BannerProps) => {
  return (
    <div className="banner">
      <div className="banner__inner container">
        <div className="banner__top">
          <h1 className="banner__title">{content.h1}</h1>
          <button
            type="button"
            className="banner__button"
            onClick={scrollToRecall}
          >
            <span>Оставить заявку</span>
          </button>
        </div>
        {content.hideImg == false ? (<img
            className="banner__image"
            src={content.bannerDesktopImg}
            alt=""
        />): (<></>)}

      </div>
    </div>
  );
};

export default Banner;
