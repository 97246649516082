import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import './style.scss';
import {FormatsProps} from "./FormatsProps";

const Formats = ({refProp, scrollToRecall, content}: FormatsProps) => {
    if(content.hideAll == true){
        return null;
    }
    return (
        <div className="formats container" ref={refProp}>
            <div className="formats__inner">
                <div className="formats__top">
                    <h2 className="formats__title">
                        Для разных <br/> помещений и форматов
                    </h2>
                    <button className="formats__button" onClick={scrollToRecall}>
                        <span>Узнать инвестиции</span>
                    </button>
                </div>
                <div className="formats__subtitle">
                    Выбери вариант для своего города
                </div>
                <button className="formats__button-mobile" onClick={scrollToRecall}>
                    <span>Оставить заявку</span>
                </button>
                <div className="formats__items">
                    {content.elems.map((t) => (
                        <div className="formats__item">
                            <div className="formats__image-wrapper">
                                <img
                                    src={t.desktopImg}
                                    alt=""
                                    className="formats__image"
                                />
                                {t.show360 == true ? (<a className="formats__t360" href={t.s360}>360&deg;</a>): null}
                                {t.showLabel == true ? (<img src={t.labelIcon} className="formats__icon"/>): null}
                            </div>
                            <div className="formats__item-title">{t.title}</div>
                        </div>
                    ))}
                </div>
                {/* мобилка */}
                <div className="formats__carousel">
                    <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={12}
                        className="formatsSwiper"
                    >
                        {content.elems.map((t) => (
                            <SwiperSlide className="formats__elem">
                                <div className="formats__image-wrapper">
                                    <img
                                        src={t.desktopImg}
                                        alt=""
                                        className="formats__image"
                                    />
                                    <div className="formats__bottom">
                                        {t.showLabel == true ? (<img src={t.labelIcon} className="formats__icon"/>): null}
                                        <div className="formats__item-title">{t.title}</div>
                                    </div>
                                    {t.show360 == true ? (<a className="formats__t360" href={t.s360}>360&deg;</a>):null}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default Formats;
