import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import './style.scss';
import {AchievementsProps} from "./achievementsProps";

const Achievements = ({ refProp , scrollToRecall, content}: AchievementsProps) => {
  return (
    <div className="achievements" ref={refProp}>
      <div className="container">
        <div className="achievements__inner">
          <div className="achievements__top">
            <h2 className="achievements__title">Посмотри наши<br/> достижения</h2>
            <div className="achievements__subtitle-mobile">
              Наша команда любит новшества
            </div>
            <button className="achievements__button" onClick={scrollToRecall}>
              <span>Стать частью истории</span>
            </button>
          </div>
          <div className="achievements__subtitle">
            Наша команда любит новшества
          </div>
          <div className="achievements__elems">
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={12}
              breakpoints={{
                768: {
                  spaceBetween: 24,
                },
              }}
              className="achievementsSwiper"
            >
              {content.elems.map((e) => (
                  <SwiperSlide className="achievements__elem">
                    <div className="achievements__elem-content">
                      {e.elem}
                    </div>
                    <div className="achievements__elem-date">{e.elemDate}</div>
                  </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Achievements;
