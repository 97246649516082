import Stage1Icon from "../assets/img/svg/terms/stage1.svg";
import Stage2Icon from "../assets/img/svg/terms/stage2.svg";
import Stage3Icon from "../assets/img/svg/terms/stage3.svg";
import React from "react";
import {TermsContentProps} from "../components/Terms/TermsProps";
import RewArtemImg from "../assets/img/rew/Artem.jpg";
import RewDmitryImg from "../assets/img/rew/Dmitry.jpg";
import RewAlexeyImg from "../assets/img/rew/Alexey.jpg";
import RewElenaImg from "../assets/img/rew/Elena.jpg";
import RewDmitry2Img from "../assets/img/rew/Dmitry2.jpg";
import RewNikitaImg from "../assets/img/rew/Nikita.jpg";
import {ParthersContentProps} from "../components/Partners/ParthersProps";
import News4Img from "../assets/img/news/4.jpg";
import News5Img from "../assets/img/news/5.jpg";
import TutkovImg from "../assets/img/sv/tutkov.jpg";
import {NewsContentProps} from "../components/News/NewsProps";
import {GrowContentProps} from "../components/Grow/GrowProps";

import FormatDKImg from "../assets/img/formats/sv/sv_dk.jpg";
import FormatFCImg from "../assets/img/formats/sv/sv_fc.jpg";
import FormatStImg from "../assets/img/formats/sv/sv_street.jpg";

import LabelSIcon from "../assets/img/svg/formats/labelS.svg";
import LabelLIcon from "../assets/img/svg/formats/labelL.svg";
import {FormatsContentProps} from "../components/Formats/FormatsProps";
import {FlexContentProps} from "../components/Flex/FlexProps";

import FTCourierImg from "../assets/img/carousel/sv/ft-courier.png";
import FTManagerImg from "../assets/img/carousel/sv/ft-manager.png";
import FTMobileAppImg from "../assets/img/carousel/sv/ft-mobile-app.png";
import FTSMMImg from "../assets/img/carousel/sv/ft-smm.png";
import FTVideoImg from "../assets/img/carousel/sv/ft-video.png";
import FTAcademiaImg from "../assets/img/carousel/sv/ft-academia.png";


import CorrectIcon from "../assets/img/svg/correct.svg";
import {BenefitsContentProps} from "../components/Benefits/BenefitsProps";
import {AchievementsContentProps} from "../components/Achievements/achievementsProps";
import BannerDesktopImg from "../assets/img/sv/sv_main.jpg";
import {BannerContentProps} from "../components/Banner/bannerProps";
import MainLogoIcon from "../assets/img/sv/logo-sv-default.png";
import FooterLogo from "../assets/img/sv/logo-footer-default.png";
import {LayoutContentType} from "../layout/LayoutProp";
import {Analytics, BrandContent} from "./BrandContent";


export const svAchievementsContentProps:AchievementsContentProps = {
    elems: [
        {
            elem: 'В апреле 2010 года открыли ресторан СушиВесла в Волжском',
            elemDate: "01.04.2010",
        },   {
            elem: 'В 2013 году вышли за пределы Волгоградской области и открыли первый ресторан в Краснодаре',
            elemDate: "14.06.2013",
        },   {
            elem: 'В августе 2021 года открылся первый ресторан СушиВесла по франшизе в Пензе',
            elemDate: "25.08.2021",
        },
        {
            elem: 'Запустили мобильное приложение в марте 2015 года',
            elemDate: '04.03.2015',
        },{
            elem: 'В феврале 2019 года открытили первый ресторан с посадкой СушиВесла',
            elemDate: '03.02.2019',
        },{
            elem: 'В октябре 2020 года мы определили “North star” стать сетью №1 в мире и открыть 5 000 ресторанов к 2030 г.',
            elemDate: '21.10.2020',
        },{
            elem: 'Запустили приложение для курьера в 2021 году',
            elemDate: '23.01.2021',
        },{
            elem: 'В июле 2014 года запустили сайт СушиВесла',
            elemDate: '17.07.2014',
        },{
            elem: 'В 2021 году число сотрудников компании превысило 1000 человек',
            elemDate: '05.04.2021',
        },{
            elem: 'В июне 2013 запустили доставку в СушиВесла',
            elemDate: '18.06.2013',
        },{
            elem: 'В мае 2021 года скатали 1 000 000 роллов',
            elemDate: '19.05.2021',
        },{
            elem: 'В декабре 2021 года накормили роллами 1 000 000 человек',
            elemDate: '12.12.2021',
        },{
            elem: 'Получили грант на развитие сети от мэра Москвы летом 2022 года',
            elemDate: '15.07.2022',
        },
    ]
}

export  const svLayoutContentType: LayoutContentType = {
    brand: "SV",
    baseUrl: 'https://xn--80aaudyq1a9a.xn--80adjkr6adm9b.xn--p1ai/',
    mainLogo: MainLogoIcon,
    mainLogoAlt: 'Франшиза СушиВесла',
    contactPhone: '8 (937) 533-38-17',
    footerLogo: FooterLogo,
    appstoreLink: 'https://apps.apple.com/ru/app/%D1%81%D1%83%D1%88%D0%B8%D0%B2%D0%B5%D1%81%D0%BB%D0%B0-%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0-%D0%B5%D0%B4%D1%8B/id982843002',
    googlePlayLink: "https://play.google.com/store/apps/details?id=com.ub.sushivesla.app&hl=ru&gl=US",
    appGalleryLink:"https://appgallery.huawei.com/app/C106378239",
    siteLink: 'https://xn--80adjkr6adm9b.xn--p1ai/',
    vacancyLink:"https://xn--80aac6chp.xn--80adjkr6adm9b.xn--p1ai/",
    email: 'franchising@sushivesla.team',
    tgName: 'sushivesla',
    vkName:'sushiveslarf',
    youtubeLink: 'https://www.youtube.com/@sushiveslarf',
    footerCopyright: '2023 ® СушиВесла, Волгоград, ул.Чуйкова 37',
    header: {
        description: 'Стань партнёром одной из самых успешных сетей суши в России. Заполняй анкету!',
        title:'Франшиза СушиВесла: официальный сайт, условия, цены, роялти',
        ogTitle:'Франшиза СушиВесла: официальный сайт, условия, цены, роялти',
        ogUrl:'https://xn--80aaudyq1a8a.xn--80adjkr6adm9b.xn--p1ai/'
    }
}

export const svBannerContentProps:BannerContentProps = {
    bannerDesktopImg: BannerDesktopImg,
    h1: (<>открой свои сушивёсла, получай прибыль и стань частью большой истории</>),
    hideImg: false
}

export const svBenefitsContentProps: BenefitsContentProps = {
    elems: [
        {
            img: {CorrectIcon},
            h3: 'Не берём допплатежей',
            p: 'У нас нет скрытых и сомнительных платежей - ты делишься с нами\n' +
                '              только процентом от оборота. Взамен мы даем платформу, обучение и\n' +
                '              поддержку'
        }, {
            img: {CorrectIcon},
            h3: 'Всему научим',
            p: 'За 3 недели научим управлять рестораном и расскажем как обучать\n' +
                '              сотрудников через СушиВесла Академию'
        }, {
            img: {CorrectIcon},
            h3: 'Поделимся опытом',
            p: 'Предоставим контакты наших федеральных поставщиков и наработанную\n' +
                '              базу маркетинговых инструментов'
        }, {
            img: {CorrectIcon},
            h3: 'Всегда будем рядом',
            p: 'Business developer поможет открыть ресторан по стандартам компании\n' +
                '              и будет сопровождать после'
        }
    ]
}

export const svCarouselContentProps = {
    carousel: [
        {
            title: 'Мы - FoodTech компания',
            second: 'Мобильное приложение',
            subtitle: (<>Нашим гостям удобно<br/> заказывать любимые роллы и сеты.</>),
            url: 'https://xn--80adjkr6adm9b.xn--p1ai/',
            hrefTitle: 'Скачать мобильное приложение',
            img: FTMobileAppImg,
            downTitle: 'Скачать приложение',
            downUrl: 'https://xn--80adjkr6adm9b.xn--p1ai/'
        },
        {
            title: 'Мы - FoodTech компания',
            second: 'SMM',
            subtitle: (<>Аккаунты в социальных сетях с общим количеством<br/> подписок более 200 тыс. человек.</>),
            url: 'https://t.me/sushivesla',
            hrefTitle: 'Подпишись на наши новости',
            img: FTSMMImg,
            downTitle: 'Подписаться на новости',
            downUrl: 'https://t.me/sushivesla'
        },
        {
            title: 'Мы - FoodTech компания',
            second: 'Приложение курьера',
            subtitle: 'Наши курьеры приедут без опозданий.',
            url: 'https://t.me/sushivesla',
            hrefTitle: 'Прочитать про курьеров',
            img: FTCourierImg,
            downTitle: 'Прочитать про курьеров',
            downUrl: 'https://t.me/sushivesla'
        },
        {
            title: 'Мы - FoodTech компания',
            second: 'СушиВесла Академия',
            subtitle: (<>Наши сотрудники точно знают<br/> и соблюдают стандарты.</>),
            url: 'https://t.me/sushivesla',
            hrefTitle: 'Подробнее про обучение',
            img: FTAcademiaImg,
            downTitle: 'Подробнее про обучение',
            downUrl: 'https://t.me/sushivesla'
        },
        {
            title: 'Мы - FoodTech компания',
            second: 'Аналитика для ресторана',
            subtitle: 'Наши партнеры знают все о своем бизнесе.',
            url: 'https://t.me/sushivesla',
            hrefTitle: 'Про управление',
            img: FTManagerImg,
            downTitle: 'Про управление',
            downUrl: 'https://t.me/sushivesla'
        },
        {
            title: 'Мы - FoodTech компания',
            second: 'Видеоконтроль',
            subtitle: (<>У нас собственная служба видеонаблюдения.<br/> Все процессы всегда под контролем.</>),
            url: 'https://t.me/sushivesla',
            hrefTitle: 'Как контролировать',
            img: FTVideoImg,
            downTitle: 'Как контролировать',
            downUrl: 'https://t.me/sushivesla'
        },
        // {
        //     title: 'Мы - FoodTech компания',
        //     second: 'Сезонная смена меню',
        //     subtitle: (<>Следуем современным тенденциям<br/> и делаем модные продукты.</>),
        //     url: 'https://xn--80adjkr6adm9b.xn--p1ai/',
        //     hrefTitle: 'Скачать мобильное приложение',
        //     img: Carousel7Img,
        //     downTitle: 'Скачать приложение',
        //     downUrl: 'https://xn--80adjkr6adm9b.xn--p1ai/'
        // },
    ]
}

export const svCircsContentProps = {
    slides: [
        {
            title: '5%',
            subtitle: 'роялти',
            tag: 'от выручки'
        },
        {
            title: '650 000 р.',
            subtitle: 'паушальный взнос',
            tag: 'есть скидки'
        },
        {
            title: 'от 10 мес.',
            subtitle: 'возврат инвестиций',
            tag: 'в среднем 16-17 мес.'
        }
    ]
}

export const svFlexContentProps: FlexContentProps = {
    title: 'Работаем гибко',
    subtitle: 'Подходим каждому',
    elems: [
        {
            text: (<><b>Москва и Санкт-Петербург:</b> Выбирай район, открывай ресторан и
                управляй бизнесом - мы поможем. С нас - поставка сырья для работы с
                нашего производства, узнаваемость бренда от ресторанов в центральных
                районах городов.</>),
            button: (<>от 5 000 000 руб. →</>)
        },
        {

            text: (<><b>Россия:</b> Открывай сеть ресторанов СушиВесла с эксклюзивными
                правами на город. С нас отлаженные бизнес-процессы, IT-сопровождение,
                маркетинговое сопровождение.</>),
            button: (<>Список свободных городов →</>)
        },
        {
            text: (<><b>Вне России:</b> Выбирай отдельный город <br/> с эксклюзивными
                правами или стань мастером-франчайзи в своей стране.</>),
            button: (<>Узнать подробнее →</>)
        },
    ]
}

export const svFormatsElemProps: FormatsContentProps = {
    elems: [
        {
            desktopImg: FormatStImg,
            labelIcon: LabelSIcon,
            s360: '/360.html#pano40/29.7/0.0/90.0',
            title: 'Стрит',
            show360: false,
            showLabel: false,
        }
        // ,{
        //     desktopImg: FormatGImg,
        //     labelIcon: LabelMIcon,
        //     s360: '/360.html#pano37/0.0/0.0/90.0',
        //     title: 'Гранд',
        //     show360: false
        // }
        ,{
            desktopImg: FormatFCImg,
            labelIcon: LabelLIcon,
            s360: '/360.html#pano33/-91.8/4.5/90.0',
            title: 'Фуд-корт',
            show360: false,
            showLabel: false,
        },{
            desktopImg: FormatDKImg,
            labelIcon: LabelLIcon,
            s360: '/360.html#pano33/-91.8/4.5/90.0',
            title: 'Дарк китчен',
            show360: false,
            showLabel: false,
        },
    ],
    hideAll: false
}

export const svGrowContentProps:GrowContentProps = {
    elems : [
        {
            title:'120 локации',
            subtitle: 'в 25 городах',
            tag: 'выросли в 1,5 раза за год',
        },{
            title:'1,7 млрд рублей',
            subtitle: 'стоит “СушиВесла” сейчас',
            tag: 'x2 год к году',
        },{
            title:(<>&#62;1 млн гостей</>),
            subtitle: 'воспользовались нашим мобильным приложением',
            tag: 'x1,4 год к году',
        },{
            title:'2,5 млн рублей',
            subtitle: (<>средняя выручка <br /> 1 ресторана в месяц</>),
            tag: 'x1,5 за год',
        }
    ]
}

export const svNewsContentProps: NewsContentProps = {
    tgLink: 'https://t.me/blinbery',
    news: [
        {
            title: 'Евгений Купко',
            date: '03.06.2022',
            img: News4Img,
            contentTitle: 'Подробнее в telegram:',
            content: (<> Основная ценность для наших компаний - это люди. Гости, сотрудники, инвесторы - все те, с кем мы взаимодействуем каждый день. ...</>),
            link: 'https://t.me/kupko_group/81 ',
        },
        {
            title: 'TUTKOVBUDKOV Agency’s',
            date: '17.05.2022',
            img: TutkovImg,
            contentTitle:'Подробнее на behance:',
            content: ('This is why we rebranded SushiVesla brand to reflect the new "Islands of Calm" philosophy built on the Three pillars: Mindfulness, Relaxedness, Tranquility. '),
            link: 'https://www.behance.net/gallery/142654543/SushiVesla-branding',
        },
        {
            title: 'Ростислав Маслов',
            date: '19.12.2022',
            img: News5Img,
            contentTitle: 'Подробнее в telegram:',
            content: ('QR меню для кафе и ресторанов такое какое оно должно быть...'),
            link: 'https://t.me/when_ros/131',
        }

    ]
}

export const svTermsContentProps: TermsContentProps = {
    title:'Получи реальные сроки открытия ресторана',
    subtitle: (<>Мы помогаем и сопровождаем партнёра <br /> на каждом этапе стройки и открытия ресторана</>),
    elems: [
        {
            img: Stage1Icon,
            title: 'Обучаешься и ищешь помещение',
            subtitle:'Под контролем опытного наставника, который передаст свой знания\n' +
                '                и поможет с выбором самой лучшей локации'
        },
        {
            img: Stage2Icon,
            title: (<>Строишь ресторан <br /> и нанимаешь сотрудников</>),
            subtitle:'Конечно, строит подрядчик под твоим контролем, а мы помогаем\n' +
                '                закупить оборудование и остальное оснащение ресторана'
        },
        {
            img: Stage3Icon,
            title: (<>Открываешь ресторан <br /> и получаешь прибыль</>),
            subtitle:'От нас StartUp команда и стартовый промо-план'
        }
    ]
}

export const svParthersContentProps: ParthersContentProps = {
    partners: [
        {
            text:'При возникновении вопросов, сотрудники УК открыто, быстро и\n' +
                '                четко находят на них ответы. Всегда готовы дать советы, исходя\n' +
                '                из накопленного за 14 лет опыта работы компании.',
            author: 'Артем',
            city: 'партнер в Йошкар-Оле',
            img:RewArtemImg
        },
        {
            text:'Открыл уже 3 ресторана в топовых ТРЦ и на подходе четвертый.',
            author: 'Дмитрий',
            city: 'партнёр в Самаре',
            img:RewDmitryImg
        },
        {
            text:'Мы первые партнеры по франшизе СушиВесла. Довольны\n' +
                '                сопровождением: ребята помогают в открытии и советуют, что\n' +
                '                улучшить в операционной работе.',
            author: 'Алексей',
            city: 'партнёр в Пензе',
            img:RewAlexeyImg
        },
        {
            text: 'Наставники обучают быстро, понятно, с вниманием. Аттестацию\n' +
                '                сдала с первого раза. СушиВесла Академия - отличная возможность\n' +
                '                обучать сотрудников, не отправляя их в длительные командировки.',
            author: 'Елена',
            city: 'партнер в Нижнем Новгороде ',
            img:RewElenaImg
        },
        {
            text: 'С помощью брокеров из УК забрал место в одном из лучших ТРЦ\n' +
                '                Московской области по выгодной цене',
            author: 'Дмитрий',
            city: 'партнёр в Москве',
            img:RewDmitry2Img
        },
        {
            text:'В этом году прошел повышение квалификации в Волгограде. Передаем\n' +
                '                новые фишки своим сотрудникам.',
            author: 'Никита',
            city: 'партнёр в Пензе',
            img:RewNikitaImg
        },
    ]
}

export const svAnalytics: Analytics = {
    yandexMetrika: 92097872,
    yandexVerification: "bbd82ef971113b18",
    googleTagManager: "G-CMMNDEB0BS"
}


export const svBrandContent: BrandContent = {
    brand: "SV",
    achievementsContentProps:svAchievementsContentProps,
    bannerContentProps:svBannerContentProps,
    benefitsContentProps: svBenefitsContentProps,
    carouselContentProps: svCarouselContentProps,
    circsContentProps: svCircsContentProps,
    flexContentProps:svFlexContentProps,
    formatsElemProps: svFormatsElemProps,
    growContentProps:svGrowContentProps,
    newsContentProps: svNewsContentProps,
    termsContentProps: svTermsContentProps,
    parthersContentProps: svParthersContentProps,
    layoutParamType: svLayoutContentType,
    analytics: svAnalytics
}