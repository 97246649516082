import React from 'react';

import './style.scss';
import {BenefitsProps} from "./BenefitsProps";
import CorrectIcon from "../../assets/img/svg/correct.svg";

const Benefits = ({content}: BenefitsProps) => {
  return (
    <div className="benefits">
      <div className="benefits__inner container">
        <div className="benefits__elems">
          {content.elems.map((t) => (
              <div className="benefits__elem">
                <img src={CorrectIcon} className="benefits__icon" />
                <h3 className="benefits__title">{t.h3}</h3>
                <p className="benefits__subtitle">{t.p}</p>
              </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benefits;
