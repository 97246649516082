import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { YMInitializer } from 'react-yandex-metrika';
import {BrandContent} from "./brand/BrandContent";
import {svBrandContent} from "./brand/SVContent";
import {bbBrandContent} from "./brand/BBContent";
import TagManager from 'react-gtm-module';

const content: BrandContent = process.env.REACT_APP_BRAND == "SV" ? svBrandContent : bbBrandContent;


const tagManagerArgs = {
    gtmId: content.analytics.googleTagManager
}
TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
      <YMInitializer accounts={[content.analytics.yandexMetrika]} options={{webvisor: true}} version="2"/>
  </React.StrictMode>
);
