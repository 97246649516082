import React from 'react';

import './style.scss';
import {FlexProps} from "./FlexProps";

const Flex = ( {scrollToRecall, content}: FlexProps) => {
  return (
    <div className="flex">
      <div className="flex__inner container">
        <h2 className="flex__title">{content.title}</h2>
        <div className="flex__subtitle">{content.subtitle}</div>
        {content.elems.map((t) => (
            <>
              <p className="flex__text">
                {t.text}
                <button  className="flex__link" onClick={scrollToRecall}>
                  {t.button}
                </button>
              </p></>
        ))}
      </div>
    </div>
  );
};

export default Flex;
