import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import './style.scss';
import {GrowProps} from "./GrowProps";

const Grow = ({content}:GrowProps) => {
  return (
    <div className="grow">
      <div className="grow__inner container">
        <h2 className="grow__title">Давай расти вместе</h2>
        <div className="grow__elems">
          {content.elems.map((t) => (
              <div className="grow__elem">
                <h4 className="grow__elem-title">{t.title}</h4>
                <div className="grow__elem-subtitle">{t.subtitle}</div>
                <div className="grow__elem-tag">
                  <span>{t.tag}</span>
                </div>
              </div>
          ))}
        </div>

        {/* мобилка */}
        <div className="grow__elems-mobile">
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={12}
            breakpoints={{
              768: {
                spaceBetween: 24,
              },
            }}
            className="growSwiper"
          >
            {content.elems.map((t) => (
                <SwiperSlide className="grow__elem">
                  <h4 className="grow__elem-title">{t.title}</h4>
                  <div className="grow__elem-subtitle">{t.subtitle}</div>
                  <div className="grow__elem-tag">
                    <span>{t.tag}</span>
                  </div>
                </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Grow;
