import React, {useState} from 'react';
import TelegramHeaderIcon from '../assets/img/svg/social/TelegramHeader.svg';
import ArrowRightIcon from '../assets/img/svg/arrowRight.svg';
import AppStoreIcon from '../assets/img/svg/social/appStore.svg';
import GooglePlayIcon from '../assets/img/svg/social/googlePlay.svg';
import HuaweiStoreIcon from '../assets/img/svg/social/huaweiStore.svg';
import MailIcon from '../assets/img/svg/social/mailIcon.svg';
import TelegramWhiteIcon from '../assets/img/svg/social/telegramWhite.svg';
import BurgerMenuIcon from '../assets/img/svg/menuBurger.svg';
import CloseMenuIcon from '../assets/img/svg/menuClose.svg';
import MenuArrowRight from '../assets/img/svg/menuArrowRight.svg';
import PhoneForwarded from '../assets/img/svg/phoneForwarded.svg';
import {Helmet} from "react-helmet";
import VkBlackIcon from '../assets/img/svg/social/vkBlack.svg';
import TelegramBlackIcon from '../assets/img/svg/social/telegramBlack.svg';
import YoutubeBlackIcon from '../assets/img/svg/social/youtubeBlack.svg';
import './layout.scss';
import {LayoutParamType} from "./LayoutProp";
import {Analytics, BrandContent} from "../brand/BrandContent";
import {svBrandContent} from "../brand/SVContent";
import {bbBrandContent} from "../brand/BBContent";

const analytics: Analytics = process.env.REACT_APP_BRAND == "SV" ? svBrandContent.analytics : bbBrandContent.analytics;

const Layout = ({
                    children,
                    scrollToRecall,
                    scrollToTerms,
                    scrollToFormats,
                    scrollToAchivments,
                    scrollToPartners,
                    scrollToNews,
                    scrollToCircs,
                    content
                }: LayoutParamType) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

    const handleMobileMenuOpen = () => {
        setIsMobileMenuOpen((prev) => !prev);
    };

    return (
        <>
            <Helmet>
                <meta name="description"
                      content={content.header.description}/>
                <title>{content.header.title}</title>
                <meta property="og:title" content={content.header.ogTitle}/>
                <meta property="og:url" content={content.header.ogUrl}/>
                <meta name="yandex-verification" content={analytics.yandexVerification} />
            </Helmet>
            <header className="header">
                <div className="header__inner container">
                    {/* лого */}
                    <a href={content.baseUrl} className={content.brand == "BB" ? "header__logo-wrapper" : "header__logo-wrapper sv"}>
                        <img src={content.mainLogo} className={content.brand == "BB" ? "header__logo" : "header__logo sv"} alt={content.mainLogoAlt}/>
                    </a>

                    {/* меню дексктоп */}
                    <nav className="header__nav">
                        <ul className="header__nav-elems">
                            <li className="header__nav-elem">
                <span onClick={scrollToTerms} className="header__nav-link">
                  <span>этапы открытия</span>
                </span>
                            </li>
                            <li className="header__nav-elem">
                <span onClick={scrollToFormats} className="header__nav-link">
                  <span>форматы</span>
                </span>
                            </li>
                            <li className="header__nav-elem">
                <span
                    onClick={scrollToAchivments}
                    className="header__nav-link active"
                >
                  <span>достижения</span>
                </span>
                            </li>
                            <li className="header__nav-elem">
                <span onClick={scrollToNews} className="header__nav-link">
                  <span>новости</span>
                </span>
                            </li>
                        </ul>
                    </nav>

                    {/* кнопка хедера */}
                    <button className="header__button" onClick={scrollToRecall}>
                        <span>контакты и поддержка</span>
                        <img src={TelegramHeaderIcon} className="header__button-icon"/>
                    </button>

                    {/* кнопка меню мобилки */}
                    <button
                        className="header__button-menu"
                        onClick={handleMobileMenuOpen}
                    >
                        <span>меню</span>
                        <img src={BurgerMenuIcon} className="header__button-menu-icon"/>
                    </button>

                    {/* мобильное меню */}
                    {isMobileMenuOpen && (
                        <div className="header__menu">
                            <div className="header__menu-inner container">
                                <div className="header__menu-top">
                                    {/* лого */}
                                    <div className="header__menu-logo-wrapper" onClick={() => {window.location.href = "/";}}>
                                        <img src={content.mainLogo} className="header__menu-logo"/>
                                    </div>

                                    <button
                                        className="header__menu-button-mobile"
                                        onClick={handleMobileMenuOpen}
                                    >
                                        <span>меню</span>
                                        <img src={CloseMenuIcon} className="header__button-mobile-icon"/>
                                    </button>
                                </div>

                                <ul className="header__content-elems">
                                    <li className="header__content-elem">
                    <span
                        onClick={() => {
                            scrollToTerms();
                            handleMobileMenuOpen();
                        }}
                        className="header__content-link"
                    >
                      Этапы открытия
                    </span>
                                        <img src={MenuArrowRight} className="header__content-icon"/>
                                    </li>
                                    <li className="header__content-elem">
                    <span
                        onClick={() => {
                            scrollToFormats();
                            handleMobileMenuOpen();
                        }}
                        className="header__content-link"
                    >
                      Форматы
                    </span>
                                        <img src={MenuArrowRight} className="header__content-icon"/>
                                    </li>
                                    <li className="header__content-elem">
                    <span
                        onClick={() => {
                            scrollToAchivments();
                            handleMobileMenuOpen();
                        }}
                        className="header__content-link"
                    >
                      Достижения
                    </span>
                                        <img src={MenuArrowRight} className="header__content-icon"/>
                                    </li>
                                    <li className="header__content-elem">
                    <span
                        onClick={() => {
                            scrollToNews();
                            handleMobileMenuOpen();
                        }}
                        className="header__content-link"
                    >
                      Новости
                    </span>
                                        <img src={MenuArrowRight} className="header__content-icon"/>
                                    </li>
                                    <li className="header__content-elem">
                    <span
                        onClick={() => {
                            scrollToPartners();
                            handleMobileMenuOpen();
                        }}
                        className="header__content-link"
                    >
                      Отзывы
                    </span>
                                        <img src={MenuArrowRight} className="header__content-icon"/>
                                    </li>
                                </ul>

                                <div className="header__content-phones">
                                    <a href={"tel:" + content.contactPhone} className="header__content-number">
                                        <img src={PhoneForwarded} className="header__content-number-icon"/>
                                        {content.contactPhone}
                                    </a>

                                    <a href={"https://t.me/" + content.tgName} className="header__content-telegram">
                                        Telegram
                                        <img src={ArrowRightIcon} className="header__content-telegram-icon"/>
                                        @{content.tgName}
                                    </a>
                                </div>

                                <button className="header__content-button">
                  <span onClick={() => {
                      scrollToRecall();
                      handleMobileMenuOpen();
                  }}>Оставить заявку</span>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </header>

            <main>{children}</main>

            <footer className="footer">
                <div className="footer__inner container">
                    <div className="footer__top">
                        <div className="footer__logo">
                            <img src={content.footerLogo} className="footer__logo-icon"/>
                        </div>
                        <div className="footer__apps">
                            <button className="footer__apps-button">
                                <span>Приложение</span>
                                <img src={ArrowRightIcon} className="footer__apps-button-icon"/>
                            </button>
                            <a
                                className="footer__apps-link"
                                href={content.appstoreLink}
                                target={'_blank'}
                            >
                                <img src={AppStoreIcon} className="footer__apps-icon"/>
                            </a>
                            <a
                                className="footer__apps-link"
                                href={content.googlePlayLink}
                                target={'_blank'}
                            >
                                <img src={GooglePlayIcon} className="footer__apps-icon"/>
                            </a>
                            <a
                                className="footer__apps-link"
                                href={content.appGalleryLink}
                                target={'_blank'}
                            >
                                <img src={HuaweiStoreIcon} className="footer__apps-icon"/>
                            </a>
                        </div>
                    </div>

                    <div className="footer__navigation">
                        <ul className="footer__navigation-elems">
                            <li className="footer__navigation-elem">
                <span
                    onClick={scrollToTerms}
                    className="footer__navigation-link"
                >
                  Этапы открытия
                </span>
                            </li>
                            <li className="footer__navigation-elem">
                <span
                    onClick={scrollToFormats}
                    className="footer__navigation-link"
                >
                  Форматы
                </span>
                            </li>
                            <li className="footer__navigation-elem">
                <span
                    onClick={scrollToAchivments}
                    className="footer__navigation-link"
                >
                  Достижения
                </span>
                            </li>
                            <li className="footer__navigation-elem">
                <span
                    onClick={scrollToNews}
                    className="footer__navigation-link"
                >
                  Новости
                </span>
                            </li>
                            <li className="footer__navigation-elem">
                                <a
                                    target="_blank"
                                    href={content.siteLink}
                                    className="footer__navigation-link"
                                >
                                    Сайт
                                </a>
                            </li>
                            <li className="footer__navigation-elem">
                                <a
                                    onClick={scrollToPartners}
                                    className="footer__navigation-link"
                                >
                                    Партнёры
                                </a>
                            </li>
                            <li className="footer__navigation-elem">
                                <a onClick={scrollToCircs} className="footer__navigation-link">
                                    Условия
                                </a>
                            </li>
                            <li className="footer__navigation-elem">
                                <a href={content.vacancyLink}
                                   className="footer__navigation-link">
                                    Вакансии
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="footer__contacts">
                        <div className="footer__contacts-left">
                            <a href={"tel:"+content.contactPhone} className="footer__phone-number">{content.contactPhone}</a>
                            <div className="footer__mails">
                                <a href="mailto:franchising@blinbery.team" className="footer__mails-link">
                                    <img src={MailIcon} className="footer__mails-link-icon"/>
                                    <span>{content.email}</span>
                                </a>
                                <a href={"https://t.me/" + content.tgName} className="footer__mails-link" target={'_blank'}>
                                    <img src={TelegramWhiteIcon} className="footer__mails-link-icon"/>
                                    <span>@{content.tgName}</span>
                                </a>
                            </div>
                        </div>
                        <div className="footer__buttons">
                            <a href={'https://vk.com/' + content.vkName} className="footer__button"
                               style={{paddingTop: '5px'}}>
                                <img src={VkBlackIcon}/>
                            </a>
                            <a href={"https://t.me/" + content.tgName} className="footer__button">
                                <img src={TelegramBlackIcon}/>
                            </a>
                            <a href={content.youtubeLink}
                               className="footer__button" style={{paddingTop: '11px'}}>
                                <img src={YoutubeBlackIcon} alt=''/>
                            </a>
                        </div>
                    </div>

                    <div className="footer__bottom">
                        <div className="footer__copyright">
                            {content.footerCopyright}
                        </div>
                        <div className="footer__contract">
                            <a href="" className="footer__contract-link">
                                Правовые договоры и соглашения
                            </a>

                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Layout;
