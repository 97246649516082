import React from 'react';
import Stage1Icon from '../../assets/img/svg/terms/stage1.svg';
import Stage2Icon from '../../assets/img/svg/terms/stage2.svg';
import Stage3Icon from '../../assets/img/svg/terms/stage3.svg';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import './style.scss';
import {TermsProps} from "./TermsProps";

const Terms = ({ refProp, scrollToRecall,content }: TermsProps) => {
  return (
    <div className="terms">
      <div className="terms__inner container" ref={refProp}>
        <div className="terms__left">
          <h2 className="terms__left-title">
            {content.title}
          </h2>
          <div className="terms__left-subtitle">
            {content.subtitle}
          </div>
          <button className="terms__button" onClick={scrollToRecall}>
            <span>Подробнее этапы</span>
          </button>
        </div>
        <div className="terms__right">
          <ul className="terms__elems">
            {content.elems.map((t) => (
                <li className="terms__elem">
                  <img src={t.img} className="terms__icon" />
                  <h3 className="terms__title">{t.title}</h3>
                  <p className="terms__subtitle">{t.subtitle}</p>
                </li>
            ))}
          </ul>
        </div>

        {/* мобилка */}
        <div className="terms__carousel">
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={12}
            className="termsSwiper"
          >
            {content.elems.map((t) => (
                <SwiperSlide className="terms__elem">
                  <img src={t.img} className="terms__icon" />
                  <h3 className="terms__title">{t.title}</h3>
                  <p className="terms__subtitle">{t.subtitle}</p>
                </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <button className="terms__button-mobile" onClick={scrollToRecall}>
          <span>Подробнее этапы</span>
        </button>
      </div>
    </div>
  );
};

export default Terms;
