import Stage1Icon from "../assets/img/svg/terms/stage1.svg";
import Stage2Icon from "../assets/img/svg/terms/stage2.svg";
import Stage3Icon from "../assets/img/svg/terms/stage3.svg";
import React from "react";
import {TermsContentProps} from "../components/Terms/TermsProps";
import RewArtemImg from "../assets/img/rew/Artem.jpg";
import RewDmitryImg from "../assets/img/rew/Dmitry.jpg";
import RewAlexeyImg from "../assets/img/rew/Alexey.jpg";
import RewElenaImg from "../assets/img/rew/Elena.jpg";
import RewDmitry2Img from "../assets/img/rew/Dmitry2.jpg";
import RewNikitaImg from "../assets/img/rew/Nikita.jpg";
import {ParthersContentProps} from "../components/Partners/ParthersProps";
import News1Img from "../assets/img/news/1.jpg";
import News2Img from "../assets/img/news/2.jpg";
import News3Img from "../assets/img/news/3.jpg";
import News4Img from "../assets/img/news/4.jpg";
import News5Img from "../assets/img/news/5.jpg";
import News6Img from "../assets/img/news/6.jpg";
import {NewsContentProps} from "../components/News/NewsProps";
import {GrowContentProps} from "../components/Grow/GrowProps";
import Desktop1Img from "../assets/img/formats/1Desktop.jpg";
import LabelSIcon from "../assets/img/svg/formats/labelS.svg";
import Desktop2Img from "../assets/img/formats/2Desktop.jpg";
import LabelMIcon from "../assets/img/svg/formats/labelM.svg";
import Desktop3Img from "../assets/img/formats/3Desktop.jpg";
import LabelLIcon from "../assets/img/svg/formats/labelL.svg";
import {FormatsContentProps} from "../components/Formats/FormatsProps";
import {FlexContentProps} from "../components/Flex/FlexProps";
import Carousel1Img from "../assets/img/carousel/1.png";
import Carousel2Img from "../assets/img/carousel/2.png";
import Carousel3Img from "../assets/img/carousel/3.png";
import Carousel4Img from "../assets/img/carousel/4.png";
import Carousel5Img from "../assets/img/carousel/5.png";
import Carousel6Img from "../assets/img/carousel/6.png";
import Carousel7Img from "../assets/img/carousel/7.png";
import CorrectIcon from "../assets/img/svg/correct.svg";
import {BenefitsContentProps} from "../components/Benefits/BenefitsProps";
import {AchievementsContentProps} from "../components/Achievements/achievementsProps";
import BannerDesktopImg from "../assets/img/banner/bannerDesktop.jpg";
import {BannerContentProps} from "../components/Banner/bannerProps";
import MainLogoIcon from "../assets/img/svg/Logo.svg";
import FooterLogo from "../assets/img/svg/footerLogo.svg";
import {LayoutContentType} from "../layout/LayoutProp";
import {Analytics, BrandContent} from "./BrandContent";



export const bbAchievementsContentProps:AchievementsContentProps = {
    elems: [
        {
            elem: 'В апреле 2012 года открыли первую блинную БлинБери в Волгограде',
            elemDate: "12.04.2012",
        },   {
            elem: 'В 2014 году вышли за пределы Волгоградской области и открыли первую блинную в Краснодаре',
            elemDate: "14.06.2014",
        },   {
            elem: 'В апреле 2012 года открыли первую блинную БлинБери в Волгограде',
            elemDate: "12.04.2012",
        },
        {
            elem: 'В августе 2019 года открылась первая блинная по франшизе в Пензе',
            elemDate: '25.08.2019',
        },{
            elem: 'Запустили доставку и мобильное приложение в марте 2020 года',
            elemDate: '04.03.2020',
        },{
            elem: 'За 2020 год открыли блинные в регионах Астрахань и Саратов',
            elemDate: '03.09.2020',
        },{
            elem: 'В октябре 2020 года мы определили “North star” стать блинной №1 в мире и открыть 5 000 блинных к 2030 г.',
            elemDate: '21.10.2020',
        },{
            elem: 'Запустили приложение для курьера в 2021 году',
            elemDate: '23.01.2021',
        },{
            elem: 'В марте 2021 года открыли первую блинную по франшизе в Москве',
            elemDate: '17.03.2021',
        },{
            elem: 'В 2021 году число сотрудников компании превысило 1000 человек',
            elemDate: '05.04.2021',
        },{
            elem: 'В апреле 2021 открыли первую блинную в аэропорту Шереметьево',
            elemDate: '18.04.2021',
        },{
            elem: 'В мае 2021 года открыли первую блинную по франшизе в Санкт-Петербурге',
            elemDate: '19.05.2021',
        },{
            elem: 'В августе 2021 открыли первую блинную на побережье Черного моря в солнечном Сочи',
            elemDate: '20.08.2021',
        },{
            elem: 'В декабре 2021 года накормили блинами 1 000 000 человек',
            elemDate: '12.12.2021',
        },{
            elem: 'За первую половину 2022 года открыли 5 блинных в Казани',
            elemDate: '04.05.2022',
        },{
            elem: 'Продали 3 000 000 блинов с открытия по май 2022 года',
            elemDate: '14.05.2022',
        },{
            elem: 'С 2020 года партнер открыл 4 блинных в топовых ТРЦ Самары',
            elemDate: '02.06.2022',
        },{
            elem: 'В июне 2022 года открыли 3 топовых блинных в центре Москвы за 3 недели',
            elemDate: '16.06.2022',
        },{
            elem: 'Получили грант на развитие сети БлинБери от мэра Москвы летом 2022 года',
            elemDate: '15.07.2022',
        },
    ]
}

export  const bbLayoutContentType: LayoutContentType = {
    brand: "BB",
    baseUrl: 'https://xn--80aaudyq1a9a.xn--90aamkcop0a.xn--p1ai/',
    mainLogo: MainLogoIcon,
    mainLogoAlt: 'Франшиза блинбери',
    contactPhone: '8 (937) 533-38-17',
    footerLogo: FooterLogo,
    appstoreLink: 'https://apps.apple.com/ru/app/%D0%B1%D0%BB%D0%B8%D0%BD%D0%B1%D0%B5%D1%80%D0%B8/id1508294248',
    googlePlayLink: "https://play.google.com/store/apps/details?id=com.blinbery.client&hl=ru",
    appGalleryLink:"https://appgallery.huawei.com/app/C105779333",
    siteLink: 'https://xn--90aamkcop0a.xn--p1ai/',
    vacancyLink:"https://xn--80aac6chp.xn--90aamkcop0a.xn--p1ai/",
    email: 'franchising@blinbery.team',
    tgName: 'blinbery',
    vkName:'blinberryrf',
    youtubeLink: 'https://www.youtube.com/channel/UC2EzjfHqUmSZjIyubLXABFw',
    footerCopyright: '2023 ® БлинБери, Волгоград, ул.Чуйкова 37',
    header: {
        description: 'Стань партнёром одной из самых успешных сетей блинных в России. Заполняй анкету!',
        title:'Франшиза БлинБери: официальный сайт, условия, цены, роялти',
        ogTitle:'Франшиза БлинБери: официальный сайт, условия, цены, роялти',
        ogUrl:'https://xn--80aaudyq1a9a.xn--90aamkcop0a.xn--p1ai/'
    }
}

export const bbBannerContentProps:BannerContentProps = {
    bannerDesktopImg: BannerDesktopImg,
    h1: (<>Открой свои БлинБери, получай прибыль и стань частью большой истории</>),
    hideImg: false
}

export const bbBenefitsContentProps: BenefitsContentProps = {
    elems: [
        {
            img: {CorrectIcon},
            h3: 'Не берём допплатежей',
            p: 'У нас нет скрытых и сомнительных платежей - ты делишься с нами\n' +
                '              только процентом от оборота. Взамен мы даем платформу, обучение и\n' +
                '              поддержку'
        }, {
            img: {CorrectIcon},
            h3: 'Всему научим',
            p: 'За 3 недели научим управлять блинной и расскажем как обучать\n' +
                '              сотрудников через БлинБери Академию'
        }, {
            img: {CorrectIcon},
            h3: 'Поделимся опытом',
            p: 'Предоставим контакты наших федеральных поставщиков и наработанную\n' +
                '              базу маркетинговых инструментов'
        }, {
            img: {CorrectIcon},
            h3: 'Всегда будем рядом',
            p: 'Business developer поможет открыть блинную по стандартам компании\n' +
                '              и будет сопровождать после'
        }
    ]
}

export const bbCarouselContentProps = {
    carousel: [
        {
            title: 'Мы - FoodTech компания',
            second: 'Мобильное приложение',
            subtitle: (<>Нашим гостям удобно<br/> заказывать любимые блины.</>),
            url: 'https://xn--90aamkcop0a.xn--p1ai/',
            hrefTitle: 'Скачать мобильное приложение',
            img: Carousel1Img,
            downTitle: 'Скачать приложение',
            downUrl: 'https://xn--90aamkcop0a.xn--p1ai/'
        },
        {
            title: 'Мы - FoodTech компания',
            second: 'SMM',
            subtitle: (<>Аккаунты в социальных сетях с общим количеством<br/> подписок более 200 тыс. человек.</>),
            url: 'https://t.me/blinbery',
            hrefTitle: 'Подпишись на наши новости',
            img: Carousel2Img,
            downTitle: 'Подписаться на новости',
            downUrl: 'https://t.me/blinbery'
        },
        {
            title: 'Мы - FoodTech компания',
            second: 'Приложение курьера',
            subtitle: 'Наши курьеры приедут без опозданий.',
            url: 'https://t.me/blinbery',
            hrefTitle: 'Прочитать про курьеров',
            img: Carousel3Img,
            downTitle: 'Прочитать про курьеров',
            downUrl: 'https://t.me/blinbery'
        },
        {
            title: 'Мы - FoodTech компания',
            second: 'БлинБери Академия',
            subtitle: (<>Наши сотрудники точно знают<br/> и соблюдают стандарты.</>),
            url: 'https://t.me/blinbery',
            hrefTitle: 'Подробнее про обучение',
            img: Carousel4Img,
            downTitle: 'Подробнее про обучение',
            downUrl: 'https://t.me/blinbery'
        },
        {
            title: 'Мы - FoodTech компания',
            second: 'Аналитика для ресторана',
            subtitle: 'Наши партнеры знают все о своем бизнесе.',
            url: 'https://t.me/blinbery',
            hrefTitle: 'Про управление',
            img: Carousel5Img,
            downTitle: 'Про управление',
            downUrl: 'https://t.me/blinbery'
        },
        {
            title: 'Мы - FoodTech компания',
            second: 'Видеоконтроль',
            subtitle: (<>У нас собственная служба видеонаблюдения.<br/> Все процессы всегда под контролем.</>),
            url: 'https://t.me/blinbery',
            hrefTitle: 'Как контролировать',
            img: Carousel6Img,
            downTitle: 'Как контролировать',
            downUrl: 'https://t.me/blinbery'
        },
        {
            title: 'Мы - FoodTech компания',
            second: 'Сезонная смена меню',
            subtitle: (<>Следуем современным тенденциям<br/> и делаем модные продукты.</>),
            url: 'https://xn--90aamkcop0a.xn--p1ai/',
            hrefTitle: 'Посмотреть на сайте',
            img: Carousel7Img,
            downTitle: 'Посмотреть на сайте',
            downUrl: 'https://xn--90aamkcop0a.xn--p1ai/'
        },
    ]
}

export const bbCircsContentProps = {
    slides: [
        {
            title: '5%',
            subtitle: 'роялти',
            tag: 'от выручки'
        },
        {
            title: '650 000 р.',
            subtitle: 'паушальный взнос',
            tag: 'есть скидки'
        },
        {
            title: 'от 10 мес.',
            subtitle: 'возврат инвестиций',
            tag: 'в среднем 16-17 мес.'
        }
    ]
}

export const bbFlexContentProps: FlexContentProps = {
    title: 'Работаем гибко',
    subtitle: 'Подходим каждому',
    elems: [
        {
            text: (<><b>Москва и Санкт-Петербург:</b> Выбирай район, открывай блинную и
                управляй бизнесом - мы поможем. С нас - поставка сырья для блинной с
                нашего производства, узнаваемость бренда от блинных в центральных
                районах городов и аэропортов.</>),
            button: (<>от 5 000 000 руб. -&#62;</>)
        },
        {

            text: (<><b>Россия:</b> Открывай сеть блинных и производство с эксклюзивными
                правами на город. С нас отлаженные бизнес-процессы, IT-сопровождение,
                маркетинговое сопровождение.</>),
            button: (<>Список свободных городов -&#62;</>)
        },
        {
            text: (<><b>Вне России:</b> Выбирай отдельный город <br/> с эксклюзивными
                правами или стань мастером-франчайзи в своей стране.</>),
            button: (<>Узнать подробнее -&#62;</>)
        },
    ]
}

export const bbFormatsElemProps: FormatsContentProps = {
    elems: [
        {
            desktopImg: Desktop1Img,
            labelIcon: LabelSIcon,
            s360: '/360.html#pano40/29.7/0.0/90.0',
            title: 'Малый',
            show360: true,
            showLabel: true,
        },{
            desktopImg: Desktop2Img,
            labelIcon: LabelMIcon,
            s360: '/360.html#pano37/0.0/0.0/90.0',
            title: 'Средний',
            show360: true,
            showLabel: true,
        },{
            desktopImg: Desktop3Img,
            labelIcon: LabelLIcon,
            s360: '/360.html#pano33/-91.8/4.5/90.0',
            title: 'Большой',
            show360: true,
            showLabel: true,
        },
    ],
    hideAll: false
}

export const bbGrowContentProps:GrowContentProps = {
    elems : [
        {
            title:'80 блинных',
            subtitle: 'в 15 городах',
            tag: 'выросли в 2,5 раза за год',
        },{
            title:'1 млрд рублей',
            subtitle: 'стоит “БлинБери” сейчас',
            tag: 'x2 год к году',
        },{
            title:(<>&#62;210 тысяч гостей</>),
            subtitle: 'воспользовались нашим мобильным приложением',
            tag: 'x1,4 год к году',
        },{
            title:'1,7 млн рублей',
            subtitle: (<>средняя выручка <br /> 1 блинной в месяц</>),
            tag: 'x1,5 за год',
        }
    ]
}

export const bbNewsContentProps: NewsContentProps = {
    tgLink: 'https://t.me/blinbery',
    news: [
        {
            title: 'РБК Новости',
            date: '21.11.2021',
            img: News1Img,
            contentTitle: 'Подробнее на РБК:',
            content: (<> В Санкт-Петербург вышла волгоградская сеть блинных «БлинБери».
                Компания уже открыла в городе три точки и собственное
                производство. В планах «БлинБери» - <br /> увеличить сеть в
                десять раз...</>),
            link: 'https://www.rbc.ru/spb_sz/21/11/2021/619764e39a7947176d6b3fda',
        },
        {
            title: 'Деловой Петербург',
            date: '19.11.2021',
            img: News2Img,
            contentTitle: 'Подробнее на ДП:',
            content: (' В Петербурге у "Теремка" появился конкурент. В Петербурге\n' +
                '                заработала волгоградская сеть блинных "БлинБери". Компания уже\n' +
                '                открыла три точки и собственное производ...'),
            link: 'https://www.dp.ru/a/2021/11/19/Blinu_negde_upast',
        },
        {
            title: 'buybrand.ru',
            date: '13.07.2022',
            img: News3Img,
            contentTitle:'Подробнее на buybrand:',
            content: (' В «БлинБери» открывает по франшизе вдвое больше точек, чем\n' +
                '                собственных. Собственник сети рассказал о возможностях развития.'),
            link: 'https://buybrand.ru/news/blinberi-otkryvaet-vdvoe-bolshe-tochek-po-franshize-chem-sobstvennykh/',
        },
        {
            title: 'Евгений Купко',
            date:'15.06.2022' ,
            img: News4Img,
            contentTitle:'Подробнее в telegram:',
            content: ('Недавно мы открыли новую блинную на Тверской. Все в режиме\n' +
                '                нон-стоп, чтобы попасть под грант...'),
            link:'https://t.me/kupko_group' ,
        },
        {
            title:'Ростислав Маслов' ,
            date: '28.04.2022',
            img:News5Img ,
            contentTitle:'Подробнее в telegram:',
            content: ('😒 Столько хайпа во круг Макдональдса «Вкусно и точка»… А вот\n' +
                '                что я думаю на этот счет...'),
            link: 'https://t.me/when_ros',
        },
        {
            title:'Продукт медиа' ,
            date: '19.11.2021',
            img: News6Img,
            contentTitle:'Подробнее на ПРОДУКТ медиа:',
            content: ('Волгоградская сеть блинных «БлинБери» планирует открыть в\n' +
                '                Петербурге до 30 кафе. Компания считает петербургский рынок\n' +
                '                интересным и, несмотря на высокую конкуренцию...'),
            link: 'https://producttoday.ru/2021/11/19/konkurent-teremka-otkroet-v-peterburge-30-blinnyh/',
        },
    ]
}

export const bbTermsContentProps: TermsContentProps = {
    title:'Получи реальные сроки открытия блинной',
    subtitle: (<>Мы помогаем и сопровождаем партнёра <br /> на каждом этапе стройки и открытия блинной</>),
    elems: [
        {
            img: Stage1Icon,
            title: 'Обучаешься и ищешь помещение',
            subtitle:'Под контролем опытного наставника, который передаст свой знания\n' +
                '                и поможет с выбором самой лучшей локации'
        },
        {
            img: Stage2Icon,
            title: (<>Строишь блинную <br /> и нанимаешь сотрудников</>),
            subtitle:'Конечно, строит подрядчик под твоим контролем, а мы помогаем\n' +
                '                закупить оборудование и остальное оснащение блинной'
        },
        {
            img: Stage3Icon,
            title: (<>Открываешь блинную <br /> и получаешь прибыль</>),
            subtitle:'От нас StartUp team и стартовый промо-план'
        }
    ]
}

export const bbParthersContentProps: ParthersContentProps = {
    partners: [
        {
            text:'При возникновении вопросов, сотрудники УК открыто, быстро и\n' +
                '                четко находят на них ответы. Всегда готовы дать советы, исходя\n' +
                '                из накопленного за 10 лет опыта работы компании.',
            author: 'Артем',
            city: 'партнер в Йошкар-Оле',
            img:RewArtemImg
        },
        {
            text:'Открыл уже 3 блинных в топовых ТРЦ и на подходе четвертая. Не за\n' +
                '                горами Тольятти)',
            author: 'Дмитрий',
            city: 'партнёр в Самаре',
            img:RewDmitryImg
        },
        {
            text:'Мы первые партнеры по франшизе БлинБери. Довольны\n' +
                '                сопровождением: ребята помогают в открытии и советуют, что\n' +
                '                улучшить в операционной работе.',
            author: 'Алексей',
            city: 'партнёр в Пензе',
            img:RewAlexeyImg
        },
        {
            text: 'Наставники обучают быстро, понятно, с вниманием. Аттестацию\n' +
                '                сдала с первого раза. БлинБери Академия - отличная возможность\n' +
                '                обучать сотрудников, не отправляя их в длительные командировки.',
            author: 'Елена',
            city: 'партнер в Нижнем Новгороде ',
            img:RewElenaImg
        },
        {
            text: 'С помощью брокеров из УК забрал место в одном из лучших ТРЦ\n' +
                '                Московской области по выгодной цене',
            author: 'Дмитрий',
            city: 'партнёр в Москве',
            img:RewDmitry2Img
        },
        {
            text:'В этом году прошел повышение квалификации в Волгограде. Передаем\n' +
                '                новые фишки своим сотрудникам.',
            author: 'Никита',
            city: 'партнёр в Пензе',
            img:RewNikitaImg
        },
    ]
}

export const bbAnalytics: Analytics = {
    yandexMetrika: 92098153,
    yandexVerification: "bbd82ef971113b18",
    googleTagManager: "G-76XBD4BHC1"
}

export const bbBrandContent: BrandContent = {
    brand: "BB",
    achievementsContentProps:bbAchievementsContentProps,
    bannerContentProps:bbBannerContentProps,
    benefitsContentProps: bbBenefitsContentProps,
    carouselContentProps: bbCarouselContentProps,
    circsContentProps: bbCircsContentProps,
    flexContentProps:bbFlexContentProps,
    formatsElemProps: bbFormatsElemProps,
    growContentProps:bbGrowContentProps,
    newsContentProps: bbNewsContentProps,
    termsContentProps: bbTermsContentProps,
    parthersContentProps: bbParthersContentProps,
    layoutParamType: bbLayoutContentType,
    analytics: bbAnalytics
}