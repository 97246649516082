import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import './style.scss';
import {CircsProps} from "./CircsProps";

const Circs = ({ refProp, content }: CircsProps) => {
  return (
    <div className="circs" ref={refProp}>
      <div className="circs__inner container">
        <h2 className="circs__title">Самые лояльные условия </h2>
        <div className="circs__elems">
          <Swiper
            breakpoints={{
              768: {
                slidesPerView: 'auto',
                spaceBetween: 14,
              },
              1024: {
                slidesPerView: 'auto',
                spaceBetween: 19,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 19,
              },
            }}
            className="circsSwiper"
          >
            {content.slides.map((t) => (
                <SwiperSlide className="circs__elem">
                  <div className="circs__elem-title">{t.title}</div>
                  <div className="circs__elem-subtitle">{t.subtitle}</div>
                  <div className="circs__elem-tag">
                    <span>{t.tag}</span>
                  </div>
                </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Circs;
