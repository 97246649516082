import React from 'react';
import ArrowRight from '../../assets/img/svg/arrowRight.svg';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import './style.scss';

import News1Img from '../../assets/img/news/1.jpg'
import News2Img from '../../assets/img/news/2.jpg'
import News3Img from '../../assets/img/news/3.jpg'
import News4Img from '../../assets/img/news/4.jpg'
import News5Img from '../../assets/img/news/5.jpg'
import News6Img from '../../assets/img/news/6.jpg'
import {NewsProps} from "./NewsProps";

const News = ({ refProp, content }: NewsProps) => {
  return (
    <div className="news" ref={refProp}>
      <div className="news__inner container">
        <div className="news__top">
          <h2 className="news__title">Новости о компании</h2>
          <div className="news__subtitle-mobile">
            Каждый день в жизни компании происходит что то новое
          </div>
          <a href={content.tgLink} className="news__button">
            <span>Подписаться на новости</span>
          </a>
        </div>
        <div className="news__subtitle">
          Каждый день в жизни компании происходит что то новое
        </div>
        <div className="news__elems">
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={12}
            breakpoints={{
              768: {
                spaceBetween: 24,
              },
            }}
            className="newsSwiper"
          >
            {content.news.map((t) => (
                <SwiperSlide className="news__elem">
                  <div className="news__elem-top">
                    <img src={t.img} alt="" className="news__elem-img" />
                    <div className="news__elem-right">
                      <div className="news__elem-title">{t.title}</div>
                      <div className="news__elem-date">{t.date}</div>
                    </div>
                  </div>
                  <div className="news__elem-content-title">{t.contentTitle}</div>
                  <p className="news__elem-content">
                    {t.content}
                  </p>
                  <a href={t.link} className="news__elem-button">
                    <span>Читать</span>
                    <img src={ArrowRight} className="news__elem-icon" />
                  </a>
                </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default News;
