import React from 'react';
import './style.scss';

import RewAlexeyImg from '../../assets/img/rew/Alexey.jpg'
import RewArtemImg from '../../assets/img/rew/Artem.jpg'
import RewDmitryImg from '../../assets/img/rew/Dmitry.jpg'
import RewDmitry2Img from '../../assets/img/rew/Dmitry2.jpg'
import RewElenaImg from '../../assets/img/rew/Elena.jpg'
import RewNikitaImg from '../../assets/img/rew/Nikita.jpg'
import {ParthersProps} from "./ParthersProps";

const Partners = ({ refProp, content }: ParthersProps) => {
  return (
    <div className="partners" ref={refProp}>
      <div className="container">
        <div className="partners__inner">
          <h2 className="partners__title">Партнёры о работе с нами</h2>
          <div className="partners__elems">
            {content.partners.map((t) => (
                <div className="partners__elem">
                  <div className="partners__elem-text">
                    {t.text}
                  </div>
                  <div className="partners__elem-bottom">
                    <div className="partners__elem-left">
                      <div className="partners__elem-author">{t.author}</div>
                      <div className="partners__elem-city">
                        {t.city}
                      </div>
                    </div>
                    <img className="partners__elem-avatar" src={t.img} />
                  </div>
                </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
