import React, { useRef } from 'react';
import Layout from './layout';
import Banner from './components/Banner';
import Terms from './components/Terms';
import Formats from './components/Formats';
import Benefits from './components/Benefits';
import Grow from './components/Grow';
import Achievements from './components/Achievements';
import Carousel from './components/Carousel';
import Partners from './components/Partners';
import News from './components/News';
import Circs from './components/Circs';
import Flex from './components/Flex';
import Recall from './components/Recall';
import './assets/scss/style.scss';
import { BrandContent} from "./brand/BrandContent";
import {svBrandContent} from "./brand/SVContent";
import {bbBrandContent} from "./brand/BBContent";


function App() {
  const recall = useRef(null);
  const terms = useRef(null);
  const formats = useRef(null);
  const achivments = useRef(null);
  const news = useRef(null);
  const partners = useRef(null);
  const circs = useRef(null);

  const content: BrandContent = process.env.REACT_APP_BRAND == "SV" ? svBrandContent : bbBrandContent;
  console.log(process.env.REACT_APP_BRAND);

  const scrollToRecall = () => {
    // @ts-ignore
    recall.current!.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };
  const scrollToTerms = () => {
    // @ts-ignore
    terms.current!.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };
  const scrollToFormats = () => {
    // @ts-ignore
    formats.current!.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };
  const scrollToAchivments = () => {
    // @ts-ignore
    achivments.current!.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };
  const scrollToNews = () => {
    // @ts-ignore
    news.current!.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };
  const scrollToPartners = () => {
    // @ts-ignore
    partners.current!.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };
  const scrollToCircs = () => {
    // @ts-ignore
    circs.current!.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };

  return (
    <div>
      <Layout
          scrollToRecall={scrollToRecall}
        scrollToTerms={scrollToTerms}
        scrollToFormats={scrollToFormats}
        scrollToAchivments={scrollToAchivments}
        scrollToNews={scrollToNews}
        scrollToPartners={scrollToPartners}
        scrollToCircs={scrollToCircs}
          content={content.layoutParamType}
      >
        {/* блок баннеров */}
        <Banner scrollToRecall={scrollToRecall} content={content.bannerContentProps}/>

        {/* блок сроков */}
        <Terms refProp={terms} scrollToRecall={scrollToRecall} content={content.termsContentProps}/>

        {/* блок форматов */}
        <Formats refProp={formats}  scrollToRecall={scrollToRecall} content={content.formatsElemProps}/>

        {/* блок плющек */}
        <Benefits content={content.benefitsContentProps}/>

        {/* блок роста */}
        <Grow content={content.growContentProps}/>

        {/* блок достижений */}
        <Achievements refProp={achivments}   scrollToRecall={scrollToRecall} content={content.achievementsContentProps}/>

        {/* блок корусель */}
        <Carousel content={content.carouselContentProps}/>

        {/* блок партнеров */}
        <Partners refProp={partners} content={content.parthersContentProps} />

        {/* блок новостей */}
        <News refProp={news} content={content.newsContentProps}/>

        {/* блок условий */}
        <Circs refProp={circs} content={content.circsContentProps} />

        {/* блок гибкой работы */}
        <Flex  scrollToRecall={scrollToRecall} content={content.flexContentProps}/>

        {/* блок перезвона */}
        <Recall refProp={recall} brand={content.brand} />
      </Layout>
    </div>
  );
}

export default App;
