import React, { useCallback, useRef } from 'react';
import LeftArrowIcon from '../../assets/img/svg/carousel/leftArrow.svg';
import RightArrowIcon from '../../assets/img/svg/carousel/rightArrow.svg';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import './style.scss';

import Carousel1Img from '../../assets/img/carousel/1.png'
import Carousel2Img from '../../assets/img/carousel/2.png'
import Carousel3Img from '../../assets/img/carousel/3.png'
import Carousel4Img from '../../assets/img/carousel/4.png'
import Carousel5Img from '../../assets/img/carousel/5.png'
import Carousel6Img from '../../assets/img/carousel/6.png'
import Carousel7Img from '../../assets/img/carousel/7.png'
import {CarouselProps} from "./CarouselProps";

const CustomNavigation = ({ children }: any) => {
  const swiper = useSwiper();
  return (
    <div className="carousel__right">
      <div onClick={() => swiper.slidePrev()} className="carousel__left-icon">
        <img src={LeftArrowIcon} />
      </div>
      {children}
      <div onClick={() => swiper.slideNext()} className="carousel__right-icon">
        <img src={RightArrowIcon} />
      </div>
    </div>
  );
};

const Carousel = ({content}:CarouselProps) => {
  // const swiper = useSwiper();

  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<span class="' + className + '">' + '</span>';
    },
  };

  return (
    <div className="carousel">
      <div className="container">
        <div className="carousel__inner">
          <Swiper
            slidesPerView={1}
            pagination={pagination}
            modules={[Pagination]}
            className="carouserSwiper"
          >
            {content.carousel.map((t) => (
                <SwiperSlide className="carousel__elem">
                  <div className="carousel__left">
                    <div className="carousel__title">{t.title}</div>
                    <div className="carousel__title-second">
                      {t.second}
                    </div>
                    <div className="carousel__elem-subtitle">{t.subtitle}</div>
                    <a href={t.url} className="carousel__button">
                      <span>{t.hrefTitle}</span>
                    </a>
                  </div>
                  <CustomNavigation>
                    <img
                        src={t.img}
                        alt=""
                        className="carousel__image"
                    />
                  </CustomNavigation>
                  <a href={t.downUrl} className="carousel__button-mobile">
                    <span>{t.downTitle}</span>
                  </a>
                </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
